import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiUrl } from "./apiConfig";

export const fetchProduct = createAsyncThunk('products/fetchProducts', async (id) => {
    const response = await fetch(`${apiUrl}category/category/${id}`);
    return response.json();
});

// Changed the name of the second async thunk to avoid action type conflict
export const fetchProductDetails = createAsyncThunk('products/fetchProductDetails', async (id) => {
    const response = await fetch(`${apiUrl}product/${id}`);
    return response.json();
});

const GetProductSlice = createSlice({
    name: 'product',
    initialState: {
        productsData: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Handling fetchProducts
        builder
            .addCase(fetchProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.productsData = action.payload.data;
            })
            .addCase(fetchProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // Handling fetchProductDetails
        builder
            .addCase(fetchProductDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProductDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.productsData = action.payload.data;
            })
            .addCase(fetchProductDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default GetProductSlice.reducer;
