import React from 'react';
import video from '../../assets/main.mp4';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/'); // Redirect to Home page
        // After navigation, scroll to the Enquiry Form section
        setTimeout(() => {
            const formSection = document.getElementById('explore');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },);
    };

    return (
        <div className="relative h-screen overflow-hidden">
            <video
                autoPlay
                loop
                muted
                className="absolute  top-1/2 left-1/2 min-w-full min-h-full w-auto h-auto transform -translate-x-1/2 -translate-y-1/2 object-cover"
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="absolute inset-0 bg-black opacity-40"></div> {/* Dark overlay for better readability */}
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center p-4">
                <h1 className="text-6xl font-bold mb-4">Elevate Your Space with Premium Marble</h1>
                <p className="mb-8 text-lg max-w-2xl">
                    Discover exquisite marble selections that blend elegance and durability for your projects.
                </p>
                <button onClick={handleRedirect} className="bg-orange-500 text-white px-8 py-3 rounded-lg hover:bg-orange-600 transition duration-300 text-lg font-semibold">
                    Explore Our Collection
                </button>
            </div>
        </div>
    );
}

export default Hero;