import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const StatsCounter = () => {
    const { ref, inView } = useInView({
        threshold: 0.1, // Trigger when 10% of the component is in view
    });
    const [hasStarted, setHasStarted] = useState(false);

    // Start counting when the component is in view
    useEffect(() => {
        if (inView && !hasStarted) {
            setHasStarted(true);
        }
    }, [inView, hasStarted]);

    return (
        <div ref={ref} style={{
            padding: '2rem',
            // backgroundColor: '#F97316',
            color: 'white',
            // marginTop: '5rem',
            // borderRadius: '0.5rem',
            // boxShadow: '0 0 10px rgba(0,0,0,0.2)'
        }}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={4}>
                    <Card style={{
                        backgroundColor: '#F97316',
                        textAlign: 'center',
                        padding: '2rem',
                        borderRadius: '0.5rem'
                    }}>
                        <CardContent>
                            <Typography variant="h2" color='#fff' component="div">
                                {hasStarted ? <CountUp start={0} end={40} duration={2.5} /> : 0}+
                            </Typography>
                            <Typography color='#fff' variant="h6">Brand Partners</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{
                        backgroundColor: '#F97316',
                        textAlign: 'center',
                        padding: '2rem',
                        borderRadius: '0.5rem'
                    }}>
                        <CardContent>
                            <Typography color='#fff' variant="h2" component="div">
                                {hasStarted ? <CountUp start={0} end={400} duration={2.5} /> : 0}+
                            </Typography>
                            <Typography color='#fff' variant="h6">Varieties</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{
                        backgroundColor: '#F97316',
                        textAlign: 'center',
                        padding: '2rem',
                        borderRadius: '0.5rem'
                    }}>
                        <CardContent>
                            <Typography color='#fff' variant="h2" component="div">
                                {hasStarted ? <CountUp start={0} end={3000} duration={2.5} /> : 0}+
                            </Typography>
                            <Typography color='#fff' variant="h6">Exclusive Products</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default StatsCounter;