import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Slider from '@mui/material/Slider';
import img1 from '../../assets/slider/1.png';
import img2 from '../../assets/slider/2.png';
import img3 from '../../assets/slider/3.png';
import img4 from '../../assets/slider/4.png';
import img5 from '../../assets/slider/5.png';
import img6 from '../../assets/slider/6.png';

const CustomSlider = () => {
    const [autoplayDelay, setAutoplayDelay] = useState(2000); // Autoplay delay in ms
    const images = [img1, img2, img3, img4, img5, img6];

    return (
        <div
            style={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                // padding: '10px',
                overflow: 'hidden',
            }}
        >
            <Swiper
                spaceBetween={20}
                centeredSlides
                loop
                autoplay={{
                    delay: autoplayDelay,
                    disableOnInteraction: true,
                }}
                // navigation
                modules={[Autoplay, Navigation]}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={image}
                            alt={`slider-image-${index}`}
                            style={{
                                width: '100%',
                                height: '350px',
                                objectFit: 'cover',
                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default CustomSlider;
