import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const addUser = createAsyncThunk('products/fetchProducts', async (data) => {
    const response = await fetch('https://inventory.risapp.co.in/public/api/user/add',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    let datas = response.json();
    console.log(datas.Promise);
    if(datas.status == 200){
        alert('user info added success');
    }
    return response.json();
});

const UseSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        loading: false,
        error :null,
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(addUser.pending,(state)=>{
            state.loading = true;
            state.error = null;
        })
        .addCase(addUser.fulfilled,(state,action)=>{
            state.loading = false;
            state.products = action.payload.data;
            
        })
        .addCase(addUser.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.error.message;
        })
    }
});
export default UseSlice.reducer;