import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchlocation } from '../../Redux/Slice/downloadSlice';
import { getDistance } from 'geolib';
import 'leaflet-routing-machine';
import bg from '../../assets/images/loc_tag.png';

// Set default icon for markers
const DefaultIcon = L.icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom red icon for user's location
const UserLocationIcon = L.icon({
    iconUrl: bg, // Use the custom image for user location
    iconSize: [30, 30], // Adjust the size as needed
    iconAnchor: [15, 30], // Anchor the icon correctly, adjust based on your image size
    popupAnchor: [0, -30], // Position the popup above the marker
});
L.Marker.prototype.options.icon = DefaultIcon;

const Showrooms = () => {
    const { locationData } = useSelector((state) => state.files);
    const dispatch = useDispatch();
    const [userLocation, setUserLocation] = useState(null);
    const [route, setRoute] = useState(null);  // Holds the route object
    const mapRef = useRef(null);

    useEffect(() => {
        dispatch(fetchlocation());

        // Get the current user's location
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                console.log(error);
                setUserLocation(null);
            }
        );
    }, [dispatch]);

    const filteredLocationData = locationData.filter(
        (showroom) => showroom.latitude && showroom.longitude
    );

    // Function to handle routing when a showroom is clicked
    const handleShowroomClick = (showroom) => {
        if (userLocation && mapRef.current) {
            // Create a route from user location to showroom
            const userLatLng = [userLocation.latitude, userLocation.longitude];
            const showroomLatLng = [showroom.latitude, showroom.longitude];

            // If there's an existing route, remove it
            if (route) {
                route.remove();
            }

            // Create a new route
            const newRoute = L.Routing.control({
                waypoints: [
                    L.latLng(userLatLng),
                    L.latLng(showroomLatLng),
                ],
                createMarker: () => null, // Disable marker on route
                routeWhileDragging: true, // Allow dragging the route
            }).addTo(mapRef.current); // Add route to the map instance from ref

            // Set the new route in the state
            setRoute(newRoute);
        }
    };

    return (
        <div className="flex flex-col pt-24 items-center justify-center p-6 bg-white">
            <div className="relative w-full">
                {/* Set z-index for MapContainer */}
                <MapContainer
                    center={[20.5937, 78.9629]} // Default to center of India
                    zoom={5}
                    style={{ height: '80vh', width: '100%', zIndex: 0, position: 'relative' }}
                    zoomControl={false}
                    whenCreated={(mapInstance) => { mapRef.current = mapInstance }} // Save map instance in ref
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution=""
                    />
                    {userLocation && (
                        // Marker for user's current location with a custom red icon
                        <Marker position={[userLocation.latitude, userLocation.longitude]} icon={UserLocationIcon}>
                            <Popup>
                                <div className="text-center">
                                    <h3 className="font-bold  text-gray-700">
                                        Current Location
                                    </h3>
                                </div>
                            </Popup>
                        </Marker>
                    )}
                    {filteredLocationData.map((showroom) => (
                        <Marker
                            key={showroom.id}
                            position={[showroom.latitude, showroom.longitude]}
                            eventHandlers={{
                                click: () => handleShowroomClick(showroom),
                            }}
                        >
                            <Popup>
                                <div className="text-center">
                                    <h3 className="font-bold text-lg text-gray-700">
                                        {showroom.name}
                                    </h3>
                                    {userLocation && showroom.latitude && showroom.longitude && (
                                        <p>
                                            Distance:{" "}
                                            {(
                                                getDistance(
                                                    { latitude: userLocation.latitude, longitude: userLocation.longitude },
                                                    { latitude: showroom.latitude, longitude: showroom.longitude }
                                                ) / 1000
                                            ).toFixed(2)}{" "}
                                            km
                                        </p>
                                    )}
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
                <div className="absolute top-4 right-4 bg-white shadow-lg rounded-lg p-4 z-10">
                    <h2 className="text-lg font-semibold text-gray-800">Showroom</h2>
                    <ul className="list-disc list-inside text-gray-600">
                        {filteredLocationData.map((showroom) => (
                            <li key={showroom.id}>{showroom.name}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <style jsx>{`
                .popup {
                    text-align: center;
                }
                .user-location-icon {
                    background-color: red;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                }
            `}</style>
        </div>
    );
};

export default Showrooms;
