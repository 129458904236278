import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Container,
    Paper,
    TextField,
    Button,
    IconButton,
    Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useDispatch } from 'react-redux';
import { addFeedback } from '../../Redux/Slice/feedbackSlce';

// Sample Indian testimonial data
const initialTestimonials = [
    {
        id: 1,
        name: 'Ravi Kumar',
        designation: 'Entrepreneur',
        review: 'The marble tiles are of excellent quality. They added a touch of elegance to my home.',
    },
    {
        id: 2,
        name: 'Anita Desai',
        designation: 'Interior Designer',
        review: 'I love working with these tiles! They have a wide variety and beautiful designs that impress my clients.',
    },
    {
        id: 3,
        name: 'Rajesh Gupta',
        designation: 'Builder',
        review: 'These marble tiles are not just beautiful, but also durable. Our projects always look stunning!',
    },
    {
        id: 4,
        name: 'Pooja Sharma',
        designation: 'Architect',
        review: 'The service and quality provided are outstanding. I highly recommend these tiles for any project.',
    },
];

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: '#f0f4f8',
    padding: theme.spacing(4),
    borderRadius: '16px',
    // boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    width: '100%',
    transition: 'transform 0.3s ease',
}));

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    borderRadius: '16px',
    backgroundColor: '#ffffff',
    padding: theme.spacing(3),
    // boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        transform: 'scale(1.02)',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
    },
}));

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState(initialTestimonials);
    const [newName, setNewName] = useState('');
    const [newDesignation, setNewDesignation] = useState('');
    const [newReview, setNewReview] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const dispatch = useDispatch()
    const formRef = useRef(null);
    const testimonialRef = useRef(null);

    const handleAddTestimonial = () => {
        if (!newName || !newDesignation || !newReview) {
            alert("Please fill in all fields.");
            return;
        }

        const newTestimonial = {
            name: newName,
            location: newDesignation,
            review: newReview,
        };
        dispatch(addFeedback(newTestimonial))
        setTestimonials([...testimonials, newTestimonial]);
        alert("Thanks for your feedback");

        setNewName('');
        setNewDesignation('');
        setNewReview('');
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: testimonialRef.current,
                start: 'top 90%',
                end: 'bottom 90%',
                scrub: true, // Smoothly transitions with scroll
                toggleActions: "play none reverse none",
            }
        });

        tl.fromTo(formRef.current,
            { x: -100, opacity: 0 },
            { x: 0, opacity: 1, duration: 1 }
        )
            .fromTo(testimonialRef.current,
                { x: 100, opacity: 0 },
                { x: 0, opacity: 1, duration: 1 },
                '<' // Align start of animation with the previous one
            );

        return () => {
            tl.kill();
        };
    }, []); // Run animation on mount

    return (
        <Container maxWidth="xl" sx={{ py: 8, display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
            <Box flex={1} mr={2} ref={formRef} sx={{ width: '100%' }}>
                <StyledPaper elevation={3}>
                    <Typography variant="h5" color="#4169E1" gutterBottom>
                        Share Your Experience With Us
                    </Typography>
                    <TextField
                        label="Your Name"
                        variant="outlined"
                        fullWidth
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Your Location"
                        variant="outlined"
                        fullWidth
                        value={newDesignation}
                        onChange={(e) => setNewDesignation(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Your Review"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={newReview}
                        onChange={(e) => setNewReview(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddTestimonial}
                        sx={{
                            backgroundColor: '#4169E1',
                            '&:hover': {
                                backgroundColor: '#4682B4',
                            },
                        }}
                    >
                        Submit
                    </Button>
                </StyledPaper>
            </Box>

            <Box flex={1} ref={testimonialRef} sx={{ width: '100%' }}>
                <StyledPaper elevation={3}>
                    <Box>
                        <Typography
                            variant="h2"
                            align="center"
                            gutterBottom
                            sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}
                        >
                            What Our Clients Say
                        </Typography>
                        <Typography
                            variant="h6"
                            align="center"
                            color="text.secondary"
                            paragraph
                            sx={{ mb: 4 }}
                        >
                            Hear from our satisfied customers who have transformed their spaces with our marble tiles.
                        </Typography>
                    </Box>
                    <StyledCard>
                        <CardContent>
                            <Typography variant="h5" color="#4682B4" gutterBottom>
                                {testimonials[currentIndex].name}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                                gutterBottom
                            >
                                {testimonials[currentIndex].designation}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{
                                    fontStyle: 'italic',
                                    textAlign: 'justify',
                                    mt: 2,
                                    fontWeight: 'bold',
                                }}
                            >
                                "{testimonials[currentIndex].review}"
                            </Typography>
                        </CardContent>
                        <Box display="flex" justifyContent="space-between" sx={{ px: 2, py: 1 }}>
                            <IconButton
                                onClick={handlePrev}
                                aria-label="Previous"
                                sx={{
                                    backgroundColor: '#4682B4',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#4682B4',
                                    },
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleNext}
                                aria-label="Next"
                                sx={{
                                    backgroundColor: '#4682B4',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#4682B4',
                                    },
                                }}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>
                    </StyledCard>
                </StyledPaper>
            </Box>
        </Container>
    );
};

export default Testimonials;
