import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, Divider, Box, Stack, ListItemText, Backdrop } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchproductcategory } from "../Redux/Slice/GetCategorySlice";
import logo from '../assets/images/png2.png';
import { FaAngleDown } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Navbars = () => {
    const [megaDropdownOpen, setMegaDropdownOpen] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isDropdownHovered, setIsDropdownHovered] = useState(false);
    const [nestedDrawerOpen, setNestedDrawerOpen] = useState(false);

    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.category);

    React.useEffect(() => {
        dispatch(fetchproductcategory());
    }, [dispatch]);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [dropdownCloseTimeout, setDropdownCloseTimeout] = useState(null);

    const handleMouseEnter = () => {
        if (dropdownCloseTimeout) {
            clearTimeout(dropdownCloseTimeout); // Clear any existing timeout
        }
        setMegaDropdownOpen(true);
    };


    const handleMouseLeave = () => {
        // Set a timeout to close the dropdown after 2 seconds
        const timeout = setTimeout(() => {
            setMegaDropdownOpen(false);
        }, 600);
        setDropdownCloseTimeout(timeout);
    };

    const StyledLink = styled(RouterLink)(({ theme }) => ({
        position: 'relative',
        display: 'inline-block',
        color: theme.palette.info.main,
        textDecoration: 'none',
        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '2px',
            width: '60%',
            backgroundColor: theme.palette.primary.main,
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease',
            transformOrigin: 'right',
        },
        '&:hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'left',
        },
    }));

    return (
        <div className=" fixed z-40 w-[94%] sm:w-[98%] lg:w-[98%]   pl-5 pr-5  rounded-full m-3 bg-black bg-opacity-50 backdrop-blur-xl text-white shadow-2xl  ">
            <AppBar position="sticky" color="transparent" elevation={0} className="rounded-lg">
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography component="div">
                        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}><img src={logo} className="h-14 w-14 scale-150 " /></Link>
                    </Typography>

                    {/* Navbar Links */}
                    <Stack direction="row" spacing={3} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                        <Link to="/" className="nav-link">Home</Link>
                        <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="nav-link"
                        >
                            <span className="cursor-pointer flex items-center gap-2">Products <FaAngleDown className="text-gray-400" /></span>
                            {megaDropdownOpen && (
                                <Box
                                    onMouseEnter={() => setIsDropdownHovered(true)}
                                    onMouseLeave={() => {
                                        setIsDropdownHovered(false);
                                        setMegaDropdownOpen(false);
                                    }}

                                    className="mega-dropdown text-black bg-white bg-opacity-95 backdrop-blur-3xl"
                                    sx={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        top: "100%",
                                        // bgcolor: "white",
                                        p: 3,
                                        boxShadow: 3,
                                        display: "flex",
                                        flexWrap: "wrap",
                                        zIndex: 1,
                                        borderRadius: 7
                                    }}
                                >
                                    {products.map((product, index) => (
                                        <Box key={index} sx={{ flex: "1 1 25%", minWidth: 150, padding: 2 }}>
                                            <Stack spacing={1}
                                                onClick={() => setMegaDropdownOpen(false)}>
                                                {product.subcategories.length > 0 ? (
                                                    <StyledLink
                                                        key={index}
                                                        to={`/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`}
                                                        className="dropdown-link"
                                                    >
                                                        {product.name}
                                                        <span className="h-10 w-20 text-blue-400"></span>
                                                    </StyledLink>
                                                ) : (
                                                    <StyledLink
                                                        key={index}
                                                        to={`/product-list/${product.id}/${encodeURIComponent(product.name)}`}
                                                        className="dropdown-link"
                                                    >
                                                        {product.name}
                                                        <span className="h-10 w-20 text-blue-400"></span>
                                                    </StyledLink>
                                                )}
                                            </Stack>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </div>

                        <Link to="/downloads" className="nav-link">Downloads</Link>
                        <Link to="/about/us" className="nav-link">About</Link>
                        <Link to="/showrooms" className="nav-link">Our Showrooms</Link>
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </Stack>

                    {/* Drawer Toggle Button (hidden on larger screens) */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>

                {/* Drawer for Mobile */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 250,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            backdropFilter: 'blur(10px)',
                            padding: '20px',
                            // borderRadius: '8px',
                        },
                    }}
                >
                    <List sx={{ marginTop: '20px' }}>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/" style={{ color: 'white', fontSize: '50px', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Home" />
                            </StyledLink>
                        </ListItem>
                        <ListItem button onClick={() => setNestedDrawerOpen(true)}>
                            <ListItemText style={{ color: 'white', textDecoration: 'none', width: '100%' }} primary="Products" />
                        </ListItem>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/downloads" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Downloads" />
                            </StyledLink>
                        </ListItem>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/about/us" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="About" />
                            </StyledLink>
                        </ListItem>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/showrooms" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Our Showrooms" />
                            </StyledLink>
                        </ListItem>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/contact" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Contact" />
                            </StyledLink>
                        </ListItem>
                    </List>
                </Drawer>


                <Drawer
                    variant="temporary"
                    open={nestedDrawerOpen}
                    onClose={() => { setNestedDrawerOpen(false); setMobileOpen(false); }}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 250,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            backdropFilter: 'blur(10px)',
                            padding: '20px',
                            // borderRadius: '8px',
                        },
                    }}
                >
                    <List>
                        <ListItem button onClick={() => setNestedDrawerOpen(false)}>
                            <ArrowBackIcon sx={{ left: '-10px', color: "#fff", font: 'bold', fontSize: '35px' }} />
                        </ListItem>
                        {products.map((product, index) => (
                            <ListItem button key={index} >
                                {product.subcategories.length > 0 ? (
                                    <StyledLink
                                        to={`/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`}
                                        style={{ color: 'white', textDecoration: 'none' }}
                                        onClick={() => {
                                            setNestedDrawerOpen(false);
                                            setMobileOpen(false);
                                        }}
                                    >
                                        <ListItemText primary={product.name} />
                                    </StyledLink>
                                ) : (
                                    <StyledLink
                                        to={`/product-list/${product.id}/${encodeURIComponent(product.name)}`}
                                        style={{ color: 'white', textDecoration: 'none' }}
                                        onClick={() => {
                                            setNestedDrawerOpen(false);
                                            setMobileOpen(false);

                                        }}
                                    >
                                        <ListItemText primary={product.name} />
                                    </StyledLink>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </AppBar>
        </div>
    );
};

export default Navbars;
