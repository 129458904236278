import EnquiryForm from './EnquiryForm';
import ExclusiveBrand from './ExclusiveBrand';
import Hero from './Hero';
import Testimonials from './Testimonials';
import Variety from './Variety';
import Slider from './Slider';
import BrandSlider from './BrandSlider';
import img1 from '../../assets/images/kajaria/img1.jpg';
import img2 from '../../assets/images/kajaria/img2.jpg';
import img3 from '../../assets/images/kajaria/img3.jpg';
import prime1 from '../../assets/images/prime/prime1.png';
import prime2 from '../../assets/images/prime/prime2.jpeg';
import prime3 from '../../assets/images/prime/prime3.png';
import bej1 from '../../assets/images/bej/bej1.jpg';
import bej2 from '../../assets/images/bej/bej2.jpg';
import bej3 from '../../assets/images/bej/bej3.jpg';
import StatsCounter from './StatsCounter';



const Home = () => {
    return (
        <>
            <Hero />
            <BrandSlider />
            <div id="explore">
                <Variety />
            </div>
            <ExclusiveBrand
                brand={'KAJARIA'}
                firstDesc={'Discover unmatched quality and style with Kajaria, our exclusive brand offering top-tier tiles that transform your space into a masterpiece.'}
                secondDesc={'Experience the elegance and durability of Kajaria tiles, bringing unparalleled beauty and resilience to your interiors.'}
                img1={img1}
                img2={img2}
                img3={img3}
                toggle={true}

            />

            <ExclusiveBrand
                brand={'PRIME'}
                firstDesc={'Crafted with the best raw materials and run on SACMI Italy turnkey state of art plant, Prime Tiles are class apart.'}

                secondDesc={'The ceramic engineering used in the manufacturing process encompasses the most recent developments and sophistication in ceramic tile manufacturing technology.'}
                img1={prime1}
                img2={prime2}
                img3={prime3}
            />
            <ExclusiveBrand
                brand={'BEJ CERAMIC'}
                firstDesc={'Quality, Creativity and Reliability" has been their core values. At Bej Ceramic we constantly strive to supply best in class Quality to their customers'}
                secondDesc={'They always keep two objectives in mind while manufacturing their Products. First, Design of their products should be Unique and Adorable & Second, Product should be of top most standard of Quality'}
                img1={bej1}
                img2={bej2}
                img3={bej3}
                toggle={true}

            />
            <StatsCounter />
            <Slider />
            {/* <Testimonials /> */}
            <div id="enquiry-form">
                <EnquiryForm />
            </div>
        </>
    );
}

export default Home;