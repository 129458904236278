import { configureStore } from "@reduxjs/toolkit";
import GetCategorySlice from "./Slice/GetCategorySlice";
import GetProductSlice from "./Slice/GetProductSlice";
import GetSubcategorySlice from "./Slice/GetSubcategorySlice";
import feedbackReducer from "./Slice/feedbackSlce";
import enquiryReducer from "./Slice/enquirySlice";
import requestReducer from "./Slice/requestSlice";
import fileReducer from "./Slice/downloadSlice";
const store = configureStore({
    reducer: {
        category: GetCategorySlice,
        products: GetProductSlice,
        subcategory: GetSubcategorySlice,
        enquiry: enquiryReducer,
        request: requestReducer,
        feedback: feedbackReducer,
        files: fileReducer,
    }
});
export default store;