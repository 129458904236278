import React from 'react';
import EnquiryForm from '../Home/EnquiryForm';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import bg from '../../assets/images/contact.jpg';

const Contact = () => {
    return (
        <>
            <div className="relative flex z-0 flex-col pt-12 items-center justify-center min-h-screen p-6 bg-gray-100">
                {/* Background Image with Overlay */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `url(${bg})`,
                    }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* Content */}
                <div className="relative z-10 text-center text-white">
                    <h1 className="text-4xl md:text-5xl font-extrabold mb-6 tracking-tight">
                        Contact Us
                    </h1>
                    <p className="text-lg mb-8 max-w-xl mx-auto px-4 md:px-0">
                        We’d love to hear from you! Please feel free to reach out via the details below or fill in the enquiry form.
                    </p>
                </div>

                {/* Contact Details */}
                <div className="relative z-10 text-center mb-8 px-4 sm:px-0">
                    <h2 className="text-2xl md:text-3xl font-semibold text-yellow-400 mb-6">
                        Get in Touch
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-xl transform transition duration-300 hover:scale-105">
                            <FaPhone className="text-yellow-600 text-3xl mr-4" />
                            <p className="text-lg text-gray-800">123 456 7890</p>
                        </div>
                        <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-xl transform transition duration-300 hover:scale-105">
                            <FaEnvelope className="text-yellow-600 text-3xl mr-4" />
                            <p className="text-lg text-gray-800">contact@xyz.com</p>
                        </div>
                        <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-xl transform transition duration-300 hover:scale-105">
                            <FaMapMarkerAlt className="text-yellow-600 text-3xl mr-4" />
                            <p className="text-lg text-gray-800">123 Xyz, Pqr City</p>
                        </div>
                    </div>
                </div>
            </div>

            <EnquiryForm />
        </>
    );
};

export default Contact;
