import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import imgg from '../../assets/images/myImage.jpg';

gsap.registerPlugin(ScrollTrigger);

const Variety = () => {
    const { products } = useSelector((state) => state.category);
    const [visibleCount, setVisibleCount] = useState(10);
    const productRefs = useRef([]);

    useEffect(() => {
        productRefs.current.forEach((product, index) => {
            // GSAP animation for the product cards when they enter the viewport
            gsap.fromTo(
                product,
                { opacity: 0, y: 30, scale: 0.8 }, // Start state: invisible, slightly below, and smaller
                {
                    opacity: 1,
                    y: 0, // End state: visible and centered
                    scale: 1, // Full size
                    duration: 1, // Duration of the animation
                    ease: "power3.out", // Smooth easing function
                    scrollTrigger: {
                        trigger: product,
                        start: "top 90%", // Start animation when the card reaches 90% of the viewport height
                        toggleActions: "play none none none", // Ensure it only plays when entering the viewport
                    },
                }
            );
        });
    }, [visibleCount]);

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 4);
    };

    return (

        <div className="py-12 p-5 md:p-32 -mt-8 lg:p-32 xl:p-32 bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen text-center">
            <h2 className="text-4xl sm:text-5xl font-extrabold mb-10 font-serif animate-pulse text-gray-900 ">
                Discover Our Exclusive Collections
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:gap-8 px-4 sm:px-6">
                {products.slice(0, visibleCount)?.map((product, index) => (
                    <Link
                        to={product.subcategories.length > 0
                            ? `/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`
                            : `/product-list/${product.id}/${encodeURIComponent(product.name)}`}
                        key={index}
                        className="bg-white border border-gray-300  shadow-lg overflow-hidden transition-all transform hover:scale-105 hover:shadow-2xl"
                        ref={el => productRefs.current[index] = el}
                    >
                        <div className="overflow-hidden ">
                            <img
                                src={product.subcategories[0]?.subcateimages}
                                alt={product.name}
                                className="w-full h-40 sm:h-48 object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                            />
                        </div>
                        <div className="p-4 bg-orange-500">
                            <h3 className=" sm:text-lg  text-white text-2xl font-semibold truncate">{product.name}</h3>
                        </div>
                    </Link>
                ))}
            </div>
            {products && products.length > visibleCount && (
                <div className="mt-8">
                    <Tooltip title="Show More" aria-label="show more">
                        <IconButton
                            onClick={handleShowMore}
                            sx={{
                                backgroundColor: '#3498db',
                                border: '2px solid #2980b9',
                                borderRadius: '50%',
                                padding: 2,
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                    boxShadow: '0px 4px 20px rgba(41, 128, 185, 0.5)',
                                },
                            }}
                        >
                            <ExpandMoreIcon fontSize="large" sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default Variety;
