import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

const ExclusiveBrand = ({ brand, firstDesc, secondDesc, img1, img2, img3, toggle }) => {
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const imagesRef = useRef([]);

    useEffect(() => {
        // Left section animation
        gsap.fromTo(
            leftRef.current,
            { y: -100, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 1.5,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: leftRef.current,
                    start: 'top 80%',
                    end: 'top 30%',
                    scrub: true,
                    toggleActions: 'play reverse play reverse',
                },
            }
        );

        // Right section animation
        gsap.fromTo(
            rightRef.current,
            { y: 100, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 1.5,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: rightRef.current,
                    start: 'top 80%',
                    end: 'top 30%',
                    scrub: true,
                    toggleActions: 'play reverse play reverse',
                },
            }
        );

        // Image hover effects (scale up on hover)
        gsap.utils.toArray(imagesRef.current).forEach((image) => {
            gsap.fromTo(
                image,
                { scale: 1 },
                {
                    scale: 1.05,
                    duration: 0.3,
                    ease: 'ease-in-out',
                    paused: true,
                    scrollTrigger: {
                        trigger: image,
                        start: 'top 90%',
                        end: 'top 60%',
                        toggleActions: 'play none none none',
                    },
                }
            );
        });
    }, []);

    return (
        <>
            <div className="hidden md:flex border-b-2 border-gray-100 flex-col md:flex-row items-center justify-between gap-8 px-4 md:px-16 py-20 bg-gradient-to-r from-gray-100 via-white to-gray-100 overflow-hidden">

                {toggle ? (
                    <>
                        <div
                            ref={leftRef}
                            className="left w-full md:w-1/2 flex flex-col items-start space-y-6 max-w-lg transition-all duration-500 ease-in-out transform hover:translate-x-2"
                        >
                            <h2 className="text-5xl font-bold text-yellow-600 mb-4 tracking-wide leading-tight">
                                {brand}
                            </h2>
                            <p className="text-lg md:text-xl text-gray-800">
                                {firstDesc}
                            </p>
                        </div>

                        <div ref={rightRef} className="right w-full md:w-1/2 grid grid-cols-1 sm:grid-cols-3 gap-6">
                            <div
                                ref={(el) => (imagesRef.current[0] = el)}
                                className="image-container group relative transform transition-all duration-500"
                            >
                                <img
                                    src={img1}
                                    alt="Kajaria Image 1"
                                    className="rounded-lg shadow-md transform group-hover:scale-105 transition duration-300"
                                />
                            </div>

                            <div
                                ref={(el) => (imagesRef.current[1] = el)}
                                className="image-container group relative transform transition-all duration-500"
                            >
                                <img
                                    src={img2}
                                    alt="Kajaria Image 2"
                                    className="rounded-lg shadow-md transform group-hover:scale-105 transition duration-300"
                                />
                            </div>

                            <div
                                ref={(el) => (imagesRef.current[2] = el)}
                                className="image-container group relative transform transition-all duration-500"
                            >
                                <img
                                    src={img3}
                                    alt="Kajaria Image 3"
                                    className="rounded-lg shadow-md transform group-hover:scale-105 transition duration-300"
                                />
                            </div>

                            <div className="col-span-2 mt-8">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-3">{brand}</h3>
                                <p className="text-gray-600">
                                    {secondDesc}
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>

                        <div ref={rightRef} className="right w-full md:w-1/2 grid grid-cols-1 sm:grid-cols-3 gap-6">
                            <div
                                ref={(el) => (imagesRef.current[0] = el)}
                                className="image-container group relative transform transition-all duration-500"
                            >
                                <img
                                    src={img1}
                                    alt="Kajaria Image 1"
                                    className="rounded-lg shadow-md transform group-hover:scale-105 transition duration-300"
                                />
                            </div>

                            <div
                                ref={(el) => (imagesRef.current[1] = el)}
                                className="image-container group relative transform transition-all duration-500"
                            >
                                <img
                                    src={img2}
                                    alt="Kajaria Image 2"
                                    className="rounded-lg shadow-md transform group-hover:scale-105 transition duration-300"
                                />
                            </div>

                            <div
                                ref={(el) => (imagesRef.current[2] = el)}
                                className="image-container group relative transform transition-all duration-500"
                            >
                                <img
                                    src={img3}
                                    alt="Kajaria Image 3"
                                    className="rounded-lg shadow-md transform group-hover:scale-105 transition duration-300"
                                />
                            </div>

                            <div className="col-span-2 mt-8">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-3">{brand}</h3>
                                <p className="text-gray-600">
                                    {secondDesc}
                                </p>
                            </div>
                        </div>
                        <div
                            ref={leftRef}
                            className="left w-full md:w-1/2 flex flex-col items-start space-y-6 max-w-lg transition-all duration-500 ease-in-out transform hover:translate-x-2"
                        >
                            <h2 className="text-5xl font-bold text-yellow-600 mb-4 tracking-wide leading-tight">
                                {brand}
                            </h2>
                            <p className="text-lg md:text-xl text-gray-800">
                                {firstDesc}
                            </p>
                        </div>


                    </>
                )}

            </div>


            {/* Mobile Layout */}
            <div className="block md:hidden w-full px-6 py-8 bg-white">
                <div className="flex flex-col items-start space-y-6">
                    <h2 className="text-5xl font-serif font-bold text-yellow-600 tracking-wide leading-tight">
                        {brand}
                    </h2>
                    <p className="text-lg text-gray-700">
                        {firstDesc}
                    </p>
                    <div className="flex justify-between gap-3 w-full">
                        <div className="image-container group relative transform transition-all duration-500 w-1/3">
                            <img
                                src={img1}
                                alt="Kajaria Image 1"
                                className="rounded-lg shadow-md w-full h-auto transform group-hover:scale-105 transition duration-300"
                            />
                        </div>
                        <div className="image-container group relative transform transition-all duration-500 w-1/3">
                            <img
                                src={img2}
                                alt="Kajaria Image 2"
                                className="rounded-lg shadow-md w-full h-auto transform group-hover:scale-105 transition duration-300"
                            />
                        </div>
                        <div className="image-container group relative transform transition-all duration-500 w-1/3">
                            <img
                                src={img3}
                                alt="Kajaria Image 3"
                                className="rounded-lg shadow-md w-full h-auto transform group-hover:scale-105 transition duration-300"
                            />
                        </div>
                    </div>
                    {/* <h3 className="text-3xl font-serif font-semibold text-gray-800 mt-4">{brand}</h3> */}
                    <p className="text-gray-600">{secondDesc}</p>
                    {/* <button className="mt-4 px-4 py-2 bg-yellow-600 text-white font-semibold rounded-md shadow-md hover:bg-yellow-700 transition duration-200">
                        Learn More
                    </button> */}
                </div>
            </div>
        </>
    );
};

export default ExclusiveBrand;
