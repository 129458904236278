import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProduct } from "../../Redux/Slice/GetProductSlice";
import { Link, useParams } from "react-router-dom";
import imgg from "../../assets/images/myImage.jpg";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ProductList = () => {
    const { productsData } = useSelector((state) => state.products);
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const categoryName = name;
    const productRefs = useRef([]);

    useEffect(() => {
        dispatch(fetchProduct(id));

        productRefs.current.forEach((product, index) => {
            gsap.fromTo(
                product,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: product,
                        start: "top 90%",
                        toggleActions: "play none none none",
                    },
                }
            );
        });
    }, [dispatch, id]);

    return (
        <div className="py-12 p-5 pt-24 md:p-32 lg:p-32 xl:p-32 bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen text-center">
            <h2 className="text-3xl sm:text-6xl bg-white text-gray-800 w-full h-[5rem] flex items-center justify-center font-semibold mb-10 rounded-lg shadow-md border-b-4 border-l-4 border-r-4 border-orange-400 transition duration-300 hover:shadow-xl hover:border-yellow-500">
                {categoryName}
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:gap-8 px-4 sm:px-6">
                {productsData.length > 0 && productsData.map((product, index) => (
                    <Link
                        to={`/product-list/${product.id}/${encodeURIComponent(product.name)}`}
                        key={index}
                        className="bg-white border border-gray-300 shadow-lg overflow-hidden transition-all transform hover:scale-105 hover:shadow-2xl"
                        ref={(el) => (productRefs.current[index] = el)}
                    >
                        <div className="overflow-hidden">
                            <img
                                src={product.ImageUrl}
                                alt={product.name}
                                className="w-full h-40 sm:h-48 object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                            />
                        </div>
                        <div className="p-4 bg-orange-500">
                            <h3 className="sm:text-lg text-white text-2xl font-semibold truncate">
                                {product.name}
                            </h3>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ProductList;
