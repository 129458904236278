import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchfiles } from '../../Redux/Slice/downloadSlice';
import previewImage from '../../assets/images/download.jpg'; // Preview image path

const Download = () => {
    const { fileData } = useSelector((state) => state.files);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchfiles());
    }, [dispatch]);

    // Function to handle file download
    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file;
        link.download = file;
        link.click();
    };

    return (
        <div className="p-8 md:p-20  pt-32 lg:pt-32 lg:p-32  xl:pt-32 min-h-screen bg-white flex flex-col items-center">

            {/* File Cards Section */}
            <div className="grid gap-6 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-5xl w-full">
                {fileData.map((item) => (
                    <div
                        key={item.id}
                        onClick={() => handleDownload(item.catalog)}
                        className="bg-white border border-gray-200 overflow-hidden shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-200"
                    >
                        {/* Fixed Image Section */}
                        <img
                            src={previewImage}
                            alt={item.title}
                            className="w-full h-48 object-cover" // Changed to w-full for responsive width
                        />

                        {/* Title Section */}
                        <div className="p-4 text-center">
                            <h3 className="text-sm md:text-md font-semibold text-gray-800">{item.title}</h3> {/* Adjusted font size */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Download;
