import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductDetails } from "../../Redux/Slice/GetProductSlice";
import { useParams } from "react-router-dom";
import { Box, Card, CardContent, CardMedia, Typography, Button, Divider, Modal, TextField, Fade, Backdrop } from "@mui/material";
import { addUser } from "../../Redux/Slice/UseSlice";
import { addRequest } from "../../Redux/Slice/requestSlice";
import bottomBanner from '../../assets/images/bottombannerproduct.jpg';


const ProductDetail = () => {
    const { productsData } = useSelector((state) => state.products);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ name: "", email: "", phone: "", qty: "", sku: "" });

    useEffect(() => {
        dispatch(fetchProductDetails(id));
    }, [dispatch, id]);

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => {
        setModalOpen(false);
        setFormData({ name: "", email: "", phone: "", qty: "", sku: "" });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(addRequest(formData));
        handleClose();
        alert(`Your request for ${formData.name} has been submitted. Our providers will reach you out within sometime`);

        setFormData({ name: "", email: "", phone: "", qty: "", sku: "" });
    };

    return (
        <>
            <div className="flex justify-center pt-24 pb-8 min-h-screen px-4 md:px-6">
                <div className="flex flex-col md:flex-row max-w-6xl w-full mt-6 space-y-6 md:space-y-0 md:space-x-10">

                    {/* Left Side: Product Image */}
                    <div className="md:w-1/2 w-full mb-6 md:mb-0 flex justify-center border border-gray-400">
                        <img
                            src={productsData.ImageUrl || 'https://via.placeholder.com/300'}
                            alt={productsData.sku}
                            className="w-full h-auto object-cover"
                        />
                    </div>

                    {/* Right Side: Product Details */}
                    <div className="md:w-1/2 w-full p-4 md:p-6 flex flex-col justify-between space-y-4 md:space-y-6">
                        {/* Product Name */}
                        <h2 className="text-2xl md:text-4xl font-semibold text-gray-800">{productsData.name || 'Product Name'}</h2>

                        <div className="border-t border-gray-300 my-2 md:my-4"></div>

                        {/* Product Details */}
                        <div className="space-y-2 md:space-y-4">
                            <p><strong>Brand:</strong> {productsData.brand}</p>
                            <p><strong>SKU:</strong> {productsData.sku}</p>
                            <p><strong>Finish:</strong> {productsData.finish}</p>
                            <p><strong>Size:</strong> {productsData.size}</p>
                            <p><strong>Color:</strong> {productsData.color}</p>
                            <p><strong>MRP:</strong> रु.{productsData.mrp}</p>
                            <div className="flex items-start">
                                <p className="font-bold mb-2 mr-2">Description:</p>
                                <p>{productsData.description}</p>
                            </div>
                        </div>

                        <div className="mt-4 md:mt-6">
                            <button
                                onClick={() => {
                                    handleOpen();
                                    setFormData({ name: "", email: "", phone: "", qty: "", sku: productsData.sku });
                                }}
                                className="w-full md:w-auto px-4 py-2 md:px-6 md:py-3 text-lg text-white font-semibold bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors duration-300"
                            >
                                Request Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={modalOpen}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: { timeout: 500 },
                }}
            >
                <Fade in={modalOpen}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: '400px' },
                            bgcolor: '#ffffff',
                            borderRadius: 3,
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '2px solid #007bff',
                        }}
                    >
                        <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'bold', color: '#007bff' }}>
                            Enquire
                        </Typography>
                        <form onSubmit={onSubmit} style={{ width: '100%' }}>
                            <TextField
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                fullWidth
                                sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                            />
                            <TextField
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                fullWidth
                                sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                            />
                            <TextField
                                label="Phone"
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                fullWidth
                                sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                            />
                            <TextField
                                label="Quantity Required"
                                type="text"
                                name="qty"
                                value={formData.qty}
                                onChange={handleChange}
                                required
                                fullWidth
                                sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    bgcolor: '#007bff',
                                    '&:hover': { bgcolor: '#0056b3' },
                                    fontWeight: 'bold',
                                    borderRadius: '8px',
                                    transition: 'background-color 0.3s',
                                    width: '100%',
                                }}
                            >
                                Submit Request
                            </Button>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default ProductDetail;
