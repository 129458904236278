import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "./apiConfig";

export const fetchfiles = createAsyncThunk('catalog/fetchfiles', async () => {
    const response = await fetch(`${apiUrl}file`);
    return response.json();
});
export const fetchlocation = createAsyncThunk('showroom/fetchlocation', async () => {
    const response = await fetch(`${apiUrl}godowns`);
    return response.json();
});

const getfiles = createSlice({
    name: 'files',
    initialState: {
        fileData: [],
        locationData: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Handle fetchProductCategory
        builder
            .addCase(fetchfiles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchfiles.fulfilled, (state, action) => {
                state.loading = false;
                state.fileData = action.payload.data;
            })
            .addCase(fetchfiles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchlocation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchlocation.fulfilled, (state, action) => {
                state.loading = false;
                state.locationData = action.payload.data;
            })
            .addCase(fetchlocation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
});

export default getfiles.reducer;
