import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import marbleImage from '../../assets/slider/5.png';
import video from '../../assets/main2.mp4';

const AboutUs = () => {
    return (
        <Box className="relative pt-24 flex flex-col items-center min-h-screen bg-cover bg-center overflow-hidden text-white">
            {/* Background Video */}
            <video
                className="absolute top-0 left-0 w-full h-full object-fill md:object-cover lg::object-cover xl:object-cover"
                autoPlay
                loop
                muted
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Gradient Overlay */}
            <Box className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black via-transparent to-black opacity-90" />

            {/* Content */}
            <Box className="relative z-10 p-10 bg-black bg-opacity-60 rounded-xl shadow-2xl max-w-3xl text-center m-8 backdrop-blur-lg border border-gray-700">
                <Typography
                    variant="h4"
                    className="text-md pb-5 md:text-3xl lg:text-3xl xl:text-3xl font-extrabold mb-6 text-teal-400 drop-shadow-xl animate__animated animate__fadeIn"
                >
                    Welcome to MP Group Nepal
                </Typography>
                <Typography
                    variant="h6"
                    className="text-xl mb-6 text-gray-300"
                >
                    Your Premier Destination for Quality Marble and Tiles
                </Typography>
                <Typography
                    variant="body1"
                    className="text-gray-300 mb-6 leading-relaxed"
                >
                    MP Group is one of the leading companies and, a
                    trendsetter in the world of Imported Marble and
                    Granite in Nepal.
                </Typography>
                <Typography
                    variant="body1"
                    className="text-gray-300 mb-6 leading-relaxed"
                > The company was established in
                    Kathmandu, Nepal, in 1989, and ever since it has been
                    raising the bar with its quality products and superior
                    services for its valuable customers. We believe in
                    building your dream, not just selling our products.
                </Typography>
                <Typography
                    variant="body1"
                    className="text-gray-300 mb-6 leading-relaxed"
                >
                    We at MP Group take pleasure in representing
                    ourselves as one of the most reputed Importer of
                    natural stone such as Imported Marble, Granite, Sand
                    stone, and Kota stone.
                </Typography>
                <Typography
                    variant="body1"
                    className="text-gray-300 mb-8 leading-relaxed"
                >
                    Apart from natural stones, you
                    can also discover each and every variety of Floor Tiles,
                    Laminate Flooring, Quartz Stones, Bathware,
                    Sanitaryware, Kitchen Sinks, and Kitchen Appliances at
                    MP Group. We have the largest collection of products
                    to choose from at a single stop thus, giving our
                    customers a hassle free and stress free environment.
                </Typography>

            </Box>

            {/* Marble Image Accent */}
            <Box
                className="absolute bottom-10 hidden md:block lg:block xl:block right-10 w-32 h-32 bg-cover bg-center rounded-full border-4 border-teal-400 shadow-lg transform transition-all duration-500 hover:scale-110"
                style={{ backgroundImage: `url(${marbleImage})` }}
            />
        </Box>
    );
};

export default AboutUs;
