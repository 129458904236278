import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from './apiConfig';

// 1. Fetch product categories
export const fetchproductcategory = createAsyncThunk('products/fetchProductCategory', async () => {
    const response = await fetch(`${apiUrl}category`);
    return response.json();
});

// 2. Fetch product subcategories
export const fetchproductsubcategory = createAsyncThunk('products/fetchProductSubcategory', async (id) => {
    const response = await fetch(`${apiUrl}category/sub_category/${id}`);
    return response.json();
});

const GetCategorySlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        productsData: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Handle fetchProductCategory
        builder
            .addCase(fetchproductcategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchproductcategory.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.data;
            })
            .addCase(fetchproductcategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // Handle fetchProductSubcategory
        builder
            .addCase(fetchproductsubcategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchproductsubcategory.fulfilled, (state, action) => {
                state.loading = false;
                state.productsData = action.payload.data; // Ensure this key exists in your state
            })
            .addCase(fetchproductsubcategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default GetCategorySlice.reducer;
