import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import store from './Redux/Store';
import { Provider } from 'react-redux';
import './App.css';
import Layout from './Layout/Layout';
import CategoryList from './Pages/Product/SubCategoryList';
import ProductList from './Pages/Product/ProductList';
import ProductDetail from './Pages/Product/ProductDetail';
import AddUser from './Pages/User/addUser';
import ProductSubcategory from './Pages/Product/ProductSubcategory';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/About/AboutUs';
import Download from './Pages/Downloads/Download';
import Contact from './Pages/Contact/Contact';
import Showrooms from './Pages/Showrrom/Showrooms';
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route path="/" element={<Home />} />
            <Route path="/sub-category-list/:id/:name" element={<ProductList />} />
            <Route path="/product-list/:id/:name" element={<ProductSubcategory />} />
            <Route path="/product-details/:id" element={<ProductDetail />} />
            <Route path="/about/us" element={<AboutUs />} />
            <Route path="/downloads" element={<Download />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/showrooms" element={<Showrooms />} />
            {/* <Route path="/categorylist" element={<CategoryList />} /> */}
            {/* <Route path="/add-user" element={<AddUser />} /> */}
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}
export default App;