import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addEnquiry } from '../../Redux/Slice/enquirySlice';

const EnquiryForm = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        remarks: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addEnquiry(formData))
        console.log('Form submitted:', formData);
        setFormData({
            name: '',
            phone: '',
            email: '',
            remarks: '',
        });
        alert("Your query has been noted. Our experts will contact you soon !");

    };

    return (
        <div className="max-w-full overflow-hidden p-4 bg-white rounded-lg shadow-lg mt-4 mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Left Side (30% Width) */}
                <div className="md:col-span-1 flex flex-col justify-center text-gray-900">
                    <h4 className="text-3xl font-bold text-yellow-500 mb-2">Book a Consultation</h4>
                    <h6 className="text-xl text-gray-900 mb-1">Transform Your Space with Our Expertise.</h6>
                    <p className="text-gray-600 mb-2">"Quality and elegance in every tile."</p>
                </div>

                {/* Right Side (70% Width) */}
                <div className="md:col-span-2">
                    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700">Your Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-3 border border-red-400 rounded focus:outline-none focus:ring focus:ring-red-200"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700">Contact Number</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-3 border border-red-400 rounded focus:outline-none focus:ring focus:ring-red-200"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="w-full p-3 border border-red-400 rounded focus:outline-none focus:ring focus:ring-red-200"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700">Additional Remarks</label>
                                <textarea
                                    name="remarks"
                                    value={formData.remarks}
                                    onChange={handleChange}
                                    rows="4"
                                    className="w-full p-3 border border-red-400 rounded focus:outline-none focus:ring focus:ring-red-200"
                                ></textarea>
                            </div>
                        </div>
                        <p className="text-sm text-gray-600 mt-2 text-center">
                            By proceeding, you are authorizing Marble Plaza and its suggested contractors to get in touch with you through calls, SMS, or e-mail.
                        </p>
                        <div className="flex justify-center mt-4">
                            <button
                                type="submit"
                                className="bg-gradient-to-r from-orange-500 to-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-orange-300 transition duration-200 flex items-center"
                            >
                                Submit Enquiry
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-2 h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H3m0 0h3m-3 0h3M21 12h-3m3 0H18m3 0h-3m0 0V9m0 3v3" />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EnquiryForm;
