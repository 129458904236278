import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "./apiConfig";

// 1. Fetch product categories
export const fetchsubcategorylist = createAsyncThunk('products/fetchProductCategory', async (id) => {
    const response = await fetch(`${apiUrl}category/category/${id}`);
    return response.json();
});
const GetSubcategorySlice = createSlice({
    name: 'product',
    initialState: {
        subcateData: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Handle fetchProductCategory
        builder
            .addCase(fetchsubcategorylist.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchsubcategorylist.fulfilled, (state, action) => {
                state.loading = false;
                state.subcateData = action.payload.data;
            })
            .addCase(fetchsubcategorylist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default GetSubcategorySlice.reducer;
