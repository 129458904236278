import React from 'react';
import { AppBar, Toolbar, Typography, Link, Box, Grid } from '@mui/material';
import { Instagram, Facebook, Twitter, LinkedIn } from '@mui/icons-material';

const Footer = () => {
    return (
        <AppBar position="static" sx={{ top: 'auto', bottom: 0, backgroundColor: '#111', color: '#fff', boxShadow: 2 }}>
            <Toolbar sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '40px 20px' }}>
                <Box sx={{ mb: 3, textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Your trusted partner for quality marble.
                    </Typography>
                    <Typography variant="body2" sx={{ maxWidth: '600px' }}>
                        Explore our range of products tailored to meet your needs.
                    </Typography>
                </Box>

                <Box sx={{ mb: 3 }}>
                    <div className="footer-menu">
                        <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
                            <li style={{ margin: '0 15px' }}><Link href="/" color="inherit" underline="hover">Home</Link></li>
                            {/* <li style={{ margin: '0 15px' }}><Link href="/downloads" color="inherit" underline="hover">Download</Link></li> */}
                            <li style={{ margin: '0 15px' }}><Link href="/about/us" color="inherit" underline="hover">About</Link></li>
                            {/* <li style={{ margin: '0 15px' }}><Link href="/showrooms" color="inherit" underline="hover">Showrooms</Link></li> */}
                            <li style={{ margin: '0 15px' }}><Link href="/contact" color="inherit" underline="hover">Contact</Link></li>
                        </ul>
                    </div>
                </Box>

                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Link href="/" color="inherit" underline="hover"><Instagram /></Link>
                    <Link href="/" color="inherit" underline="hover"><Facebook /></Link>
                    <Link href="/" color="inherit" underline="hover"><Twitter /></Link>
                </Box>

                <Typography variant="body2" sx={{ mb: 1 }}>
                    © {new Date().getFullYear()} MP Group Nepal. All rights reserved.
                </Typography>
                <Typography variant="body2">
                    Made by <span style={{ fontWeight: 'bold' }}>Ruminate IT Solution</span>
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
