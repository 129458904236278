import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "./apiConfig";

export const addFeedback = createAsyncThunk(
    "addFeedback",
    async (datas, { rejectWithValue }) => {
        try {
            const response = await fetch(`${apiUrl}feedback/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {
        addStat: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addFeedback.pending, (state) => {
                state.addStat = 'pending';
            })
            .addCase(addFeedback.fulfilled, (state, action) => {
                state.addStat = 'succeeded';
            })
            .addCase(addFeedback.rejected, (state, action) => {
                state.addStat = 'failed';
                state.error = action.payload;
            })
    }
});

export default feedbackSlice.reducer;
